/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('../../../exhibit.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh 
        geometry={nodes.Vanity.geometry}
        material={materials.Material} 
        position={[-2.49, -1.4, 9.68]}
        scale={[1, 2.1, 1]}
      >
        <mesh
          geometry={nodes['Screen_Shot_2021-03-04_at_63417_PM001'].geometry}
          material={nodes['Screen_Shot_2021-03-04_at_63417_PM001'].material}
          position={[1.01, 0.01, 0.01]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[-2.47, -2.47, -1.83]}
        />
        <mesh
          geometry={nodes['Screen_Shot_2021-03-04_at_63417_PM002'].geometry}
          material={nodes['Screen_Shot_2021-03-04_at_63417_PM002'].material}
          position={[0, 0.01, -1.02]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[-2.47, -2.47, -1.83]}
        />
        <mesh
          geometry={nodes['Screen_Shot_2021-03-04_at_63417_PM003'].geometry}
          material={materials['Screen Shot 2021-03-04 at 6.34.17 PM.001']}
          position={[-1.02, 0, -0.01]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[-2.47, -2.47, -1.83]}
        />
        <mesh
          geometry={nodes['Screen_Shot_2021-03-04_at_63417_PM004'].geometry}
          material={nodes['Screen_Shot_2021-03-04_at_63417_PM004'].material}
          position={[0.01, 0, 1.01]}
          rotation={[Math.PI / 2, 0, -3.13]}
          scale={[-2.47, -2.47, -1.83]}
        />
      </mesh>
      <group position={[-1.22, 3.44, 5.2]} scale={[-7.04, -10.51, -7.04]}>
        <mesh geometry={nodes.Cube001.geometry} material={materials.lilac} />
        <mesh geometry={nodes.Cube001_1.geometry} material={materials['Wood.001']} />
      </group>
      <mesh 
        geometry={nodes.chair.geometry}
        material={materials.Chair}
        position={[-2.5, -2, 6.5]} 
        rotation={[3.15, 3.15, 0]}
        scale={[.7, 0.06, 0.61]} //adjust later

      />
      <mesh
        geometry={nodes.Cylinder.geometry}
        material={materials['Material.001']}
        position={[-2.35, 0.33, 9.86]}
        rotation={[0, -1.49, 0]}
        scale={[0.61, 0.1, 0.61]}
      />
      <mesh
        geometry={nodes.MirrorCase.geometry}
        material={nodes.MirrorCase.material}
        position={[-2.34, 1.97, 9.63]}
        rotation={[0, -1.49, 1.26]}
        scale={[0.68, 0.68, 0.54]}
      />
      <mesh
        geometry={nodes.attach1.geometry}
        material={nodes.attach1.material}
        position={[-2.39, 1.7, 9.74]}
        rotation={[-0.84, 0.05, -1.51]}
        scale={[-0.03, -0.01, -0.03]}
      />
      <mesh
        geometry={nodes.Plane001.geometry}
        material={nodes.Plane001.material}
        position={[-2.37, 1.72, 9.65]}
        rotation={[0, -1.49, -1.88]}
        scale={[0.15, -0.42, 0.15]}
      />
      <mesh
        geometry={nodes.attach2.geometry}
        material={nodes.attach2.material}
        position={[-2.33, 1.7, 9.74]}
        rotation={[-0.84, 0.05, -1.51]}
        scale={[-0.03, -0.01, -0.03]}
      />
      <mesh
        geometry={nodes.Mirror.geometry}
        material={materials['Material.003']}
        position={[-2.96, 2.06, 9.43]}
        rotation={[-1.27, 0.02, 1.85]}
        scale={[0.24, -1.78, 0.61]}
      />
      <mesh
        geometry={nodes.Plane002.geometry}
        material={materials['Material.004']}
        position={[-2.34, 1.98, 9.63]}
        rotation={[0, -1.49, 1.26]}
        scale={[0.59, 0.74, 0.47]}
      />
      <mesh
        geometry={nodes.PachucaCollage.geometry}
        material={materials.PachucaCollage}
        position={[-3.19, 3.61, 10.55]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[4.35, 4.35, 4.35]}
      />
      <mesh
        geometry={nodes.CholaCollage.geometry}
        material={materials.CholaCollage}
        position={[-3.17, 3.79, 10.681]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[6.34, 5.54, 5.54]}
      />
      <mesh
        geometry={nodes.Homegirl_and_Demi.geometry}
        material={materials['Homegirl and Demi']}
        position={[-6.67, 4.21, 7.05]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[5.56, 5.56, 5.56]}
      />
      <mesh
        geometry={nodes.Cylinder001.geometry}
        material={materials.Carpet}
        position={[-2.05, -3.22, 4.17]}
        scale={[3.97, 0.04, 3.97]}
      />
    </group>
  )
}

useGLTF.preload('../../../exhibit.glb')

import React, {Component} from 'react';
import {withRouter} from 'react-router';
import NavBar from './navBar';
import '../style/makeUp.css';
import Normal from '../img/normalChola.png';
import Clown from '../img/chola clown.png';
import Ice from '../img/ice blue.png';
import Spider from '../img/spider lashes.png';


class MakeUp extends Component{

    render(){
        return(
            <div className="makeup">
                <NavBar/>
                <h1 id="header">Make-Up Tutorials</h1>
                <h2>Coming Soon: Makeup tutorials for each look!</h2>
                <div className="gallery"> {/*Clicking on a picture sends you to the video- but the videos dont exist yet */}
                    <a target="_blank" href="">
                        <img src={Normal} alt="Chola Clásica/Classic Chola"/>
                    </a>
                    <div class="desc">"Chola Clásica/Classic Chola"</div>
                </div>
                <div className="gallery">
                    <a target="_blank" href="">
                        <img src={Clown} alt="La Payasa Moderna/Modern Clownery" />
                    </a>
                    <div class="desc">"La Payasa Moderna/Modern Clownery"</div>
                </div>
                <div className="gallery">
                    <a target="_blank" href="">
                        <img src={Ice} alt="Icy Blue" />
                    </a>
                    <div class="desc">"Icy Blue"</div>
                </div>
                <div className="gallery">
                    <a target="_blank" href="">
                        <img src={Spider} alt="Spidery Lahes" />
                    </a>
                    <div class="desc">"Spidery Lashes"</div>
                </div>


            </div>
        )
    }
}


export default withRouter(MakeUp)